@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;800&display=swap');

.absolute {
    position: absolute;
}

.flex, .pagination-nav {
    display: flex;
}

.inset-0 {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
}

.items-center, .menuExternalLink_tcZa, .row--align-center {
    align-items: center;
}

.justify-center {
    justify-content: center;
}

.md\:text-8xl {
    font-size: 6rem;
    line-height: 1;
}


.text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255/var(--tw-text-opacity));
}

.font-thin {
    font-weight: lighter;
    font-family: "Open Sans", sans-serif;
    font-size: 2vw;
}

.font-bold {
    font-weight: bold;
    font-family: 'Open Sans', sans-serif;
}
.text-6xl {
    font-size: 3.75rem;
    line-height: 1;
}

.text-into{
    padding-left: 7vw;
    width: 33%;
    margin-top: 90px;
}

.parallax-none {
    display: flex;
    /* position: relative;
    width: 100%; */
}

.parallax-none img {
    width: 100%;
    min-width: 390px;
    object-fit: cover;
    object-position: 50% 0;
}

.parallax-none span { 
    position: absolute;
    color: white;
    font-size: 30px;
    width: 520px;
    top: 40%;
    left: 12%;
}

@media only screen and (max-width: 860px) {
    .parallax-none span { 
        width: 50%;
        font-size: 25px;
        top: 170px;
        left: 32px;
    }

    .parallax-none img {
        width: 100%;
        height: 480px;
        object-fit: cover;
        object-position: 40% 0;
    }

    .carousel-inner {
        overflow: none;
    }
}

@media only screen and (max-width: 800px) {
    .parallexAspectRatio {
        aspect-ratio: 3/1;
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 94%, 0 88%);
        clip-path: polygon(0 0, 100% 0, 100% 94%, 0 88%);
        height: 317px;
        z-index: 1;
    }
    .parallexAspectRatio-text {
        margin-top: 0px;
    }
    
    .parallexAspectRatio div[data-testid="layer-2"] {
        inset: -204px 0px !important;
        background-size: none !important;
    }

    .accordion-line-time {
        display: block;
        position: relative;
        top: -40px;
    }

    .accordion-line-time .css-1elwnq4-MuiPaper-root-MuiAccordion-root:last-of-type {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        padding: 13px 0px;
        background: #f6f5f5;
    }

    .accordion-line-time .css-yw020d-MuiAccordionSummary-expandIconWrapper, .accordion-line-time .css-yw020d-MuiAccordionSummary-expandIconWrapper .Mui-expanded {
        position: relative;
        left: -163px;
    }

    .text-into {
        padding-left: 4vw;
        width: 65%;
    }

    .font-thin {
        font-weight: lighter;
        font-family: "Open Sans", sans-serif;
        font-size: 5vw;
    }
}

@media only screen and (max-width: 300px) {
    .accordion-line-time .css-yw020d-MuiAccordionSummary-expandIconWrapper, .accordion-line-time .css-yw020d-MuiAccordionSummary-expandIconWrapper .Mui-expanded  {
        position: relative;
        left: -66px;
    }
}
  
@media only screen and (min-width: 600px) {
    .parallexAspectRatio {
        aspect-ratio: 3/1;
    }
    .parallexAspectRatio-text {
        margin-top: 90px;
    }

    .accordion-line-time {
        display: none;
    }
}
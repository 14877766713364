.card-main {
    padding: 100px 150px;
}

.form-umbral {
    margin-top: 50px;
}

.form-umbral-grid {
    width: 100%;
}

.form-button {
    text-align: center;
}

.form-button .MuiButton-root {
    border-radius: 40px;
    padding: 20px 72px;
    margin-top: 30px;
    color: white;
}

.form-button-background-morado .MuiButton-root {
    background-color: #4e2378;
}

.form-button-background-morado .MuiButton-root:hover {
    background-color: #361d50;
}

.form-button-background-azul .MuiButton-root {
    background-color: #35a3e8;
}

.form-table {
    margin-top: 100px;
}

.form-table-period {
    margin-bottom: 50px;
}

.form-table h3 {
    margin-bottom: 30px;
}

.table-post-footer {
    text-align: right;
    margin-top: 30px;
}

.table-post-footer span {
    margin-right: 80px;
    font-size: 17px;
}

.dialog {
    text-align: center;
    padding: 81px 40px;
}

.dialog-button .MuiButton-root {
    border-radius: 40px;
    padding: 10px 72px;
    margin-top: 60px
}

.texto-aviso {
    color: red;
}

.caja-mensaje-errores {
    margin-top: 55px;
    color: red;
}

#invalidMessages {
    color: red;
    height: 191px;
    overflow: auto;
    padding: 0px 21px;
    width: 65%;
}

.edit-data {
    display: flex;
}

.edit-data > p, .edit-data > .MuiOutlinedInput-root {
    width: 80%;
}

.edit-data > button {
    width: 20%;
    border: none;
    background: none;
    cursor: pointer;
}


.edit-data > button:hover {
    color: rgb(88, 173, 233);
}

.edit-data > button:disabled {
    color: rgb(179, 179, 179);
    cursor: initial
}

.cancel-data:hover {
    color: red !important;
}

.acept-data:hover {
    color: rgb(9, 190, 30);
}

.acept-data:disabled {
    color: rgb(179, 179, 179);
    cursor: initial
}

@media only screen and (max-width: 600px) {
    .card-main {
        padding: 100px 50px;
    }
}

.nota {
    color: #aaaaaa;
}

.nota_asterisco {
    color: #ff0000;
}

.tranferList > .MuiCardHeader-content > span {
    height: 60px;
}

.nota_mensaje_error {
    margin: 0;
    font-size: 0.75rem;
    margin-top: 3px;
    text-align: left;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    color: #f44336
}

.select_admin {
    width: 200px;
}

.formRegPeriodCert .makeStyles-textField-8 {
    width: 250px;
}

.cell-center {
    justify-content: center !important;
}

.hader-purple .MuiDataGrid-columnHeader,.hader-purple .MuiDataGrid-columnHeaders{
    background-color: #4e2378;
    color: white;
}

.hader-orange .MuiDataGrid-columnHeader,.hader-orange .MuiDataGrid-columnHeaders{
    background-color: #e96c1a;
    color: white;
}

.MuiDataGrid-columnsContainer {
    height: 250px;
}
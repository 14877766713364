@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;800&display=swap');

.link {
    color: #4285f4;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
  }

.link-button {
  cursor: pointer;
  text-decoration: underline;
  border: none;
  background: none;
  margin-top: 10px;
}
  
.spanHeader{
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
}

.title {
    flex-grow: 1;
    text-align: right;
    color:gray;
}

.captcha {
  text-align: center;
  margin: 38px 60px;
  color:red;
}

.appbar {
  display: flex;
  gap: 10px;
  height: 140px;
  background-color: #f3f3f3;
  align-items: center;
  width: 100%;
}

.appbar-admin-menu {
  padding-left: 20px;
}

.header-nav {
  display: flex;
  align-items: center;
  gap: 15px
}

.header-nav a {
  border-radius: 10px;
  width: 159px;
  color: #968787;
  font-size: 21px;
  text-decoration: none;
  font-weight: lighter;
}

.boton-aplica-ref {
  width: 200px;
  padding: 9px 0px;
  background-color: #fd4700;
  border-radius: 20px;
  color: white;
  text-decoration: none;
  font-weight: 400;
  font-size: 20px;

}

.header-logo {
  width: 50%;
  display: flex;
}

.header-logo > img {
  margin-left: 70px;
}

.header-boton {
  width: 50%;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 25px;
}

.header-boton > button {
  height: 50px;
  border-radius: 30px;
  border: 0px;
  width: 200px;
  color: #fff;
}

.boton-inicio {
  background-color: #011d60;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 100px;
}

.boton-registro {
  background-color: #50a4e0;
  cursor: pointer;
}

.boton-registro2 {
  background-color: #50a4e0;
  cursor: pointer;
  font-size: 1.2rem;
}

@media only screen and (max-width: 1574px) {
  .boton-inicio {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 1473px) {

  .appbar {
    /* flex-direction: column; */
    height: 230px;
    min-width: 390px;
    gap: 15px;
  }

  .header-nav {
    flex-direction: column;
    gap: 15px
  }

  /* .header-logo {
    width: 100%;
    justify-content: center;
  } */

  .header-logo > img {
    min-width: 390px;
    margin-left: 0;
  }

  /* .header-boton {
    width: 100%;
    justify-content: center;
    flex-direction: column;
  } */

  .boton-inicio {
    margin-right: 0;
  }

  .appbar-admin {
    height: 270px;
  }
}

@media only screen and (max-width: 1044px) {
  .appbar {
    flex-direction: column;
    justify-content: center;
    height: 430px;
  }

  .header-boton {
    width: 100%;
    justify-content: center;
  }

  .header-logo {
    width: 100%;
    justify-content: center;
  }

  .header-nav {
    position: relative;
    top: -15px;
  }
}

@media only screen and (max-width: 600px) {
  .appbar {
    flex-direction: column;
  }

  #appbar-mobil {
    display: block;
  }
  
  .loginButton{
    width: 20%;
  }

  .captcha {
    text-align: center;
    margin: 38px -15px;
    color:red;
  }

  .captcha > div > div > div {
    width: 277px;
  }
}

@media only screen and (min-width: 600px) {
  #appbar {
    display: block;
  }

  #appbar-mobil {
    display: none;
  }

  .loginButton{
    width: 10%;
  }
} 
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;800&display=swap');

.font-landing * {
    font-family: 'Open Sans', sans-serif !important;
}

* {
    font-family: 'Open Sans', sans-serif !important;
}
.font-color {
    color: #282728;
}

.number-bold{
    font-weight: bold;
    color: #000;
}

hr {
    border: 1px solid #272f51;
    width: 11%;
    margin-left: 0;
}
.font-footer{
    font-family: 'Open Sans', sans-serif !important;
    font-weight: lighter;
    font-size: 25px;
    color: white;
}

.button_ovalado{
    color: #ffffff !important;
    border: 1px solid #ffffff !important;
    border-radius: 40px !important;
    background:#2E3B55 !important;
}

.button_ovalado_disabled{
    border: 1px solid #ffffff !important;
    border-radius: 40px !important;
}

.MuiTreeItem-label{
    font-family: 'Open Sans', sans-serif !important;
    font-size: 20px !important;
}

.MuiCollapse-wrapperInner > p {
    font-family: 'Open Sans', sans-serif !important;
    font-size: 16px !important;
}

.react-responsive-modal-modal{
    border-radius: 25px;
    background-color: #F3F3F3;
}

#modal-confirmacion > *{
    font-family: 'Open Sans', sans-serif !important;
    font-weight: lighter;
    background-color: #F3F3F3;
    border: 5;
    
}

.content-community-face {
    height: 100%;
    background-image: url("../img/fondo_box_01.jpg");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    color: #fff;
    padding: 100px 0px;
    margin-bottom: 25px;
    font-size: 18px !important;
}

.content-community-face_button > a > button {
    background-color: #fff;
    color: #323750;
}

.content-community-face h1{
    margin: 0px;
}

.content-community-face button {
    background: none;
    border: solid 1px #fff;
    border-radius: 100px;
    color: #fff;
    padding: 25px 70px;
    margin-top: 30px;
    font-weight: bold;
    cursor: pointer;
}

.content-carrusel {
    width: 80%;
    margin-left: 10%;
}

.content-carrusel_item {
    display: flex !important;
    justify-content: center;
    text-align: center;
}

.content-carrusel_item img {
    display: initial !important;
}

.content-collaborators {
    padding: 35px 40px;
}
  
.content-header-collaborators {
    text-align: center;
    margin: 52px 0px;
}

.content-collaborators-button {
    margin: 30px 0px;
    text-align: center;
}

.content-collaborators-button a {
    background-color: #2E3B55;
    border: solid 1px #2E3B55;
    border-radius: 100px;
    color: #fff !important;
    padding: 25px 70px;
    margin-top: 50px;
    font-weight: bold;
    cursor: pointer;
    top: 33px;
    position: relative;
    text-decoration: none;
}

.content-header-collaborators span:nth-child(1) {
    font-size: 25px;
}

.conten-contact div {
    display: flex;
    margin-top: 10px;
}

.conten-contact div > img {
    margin-right: 10px;
}

@media only screen and (max-width: 600px) {
    .grid-paper {
        display: none !important;
    }

    .grid-paper-mobil {
        display: flex !important;
    }

    .btn-login {
        display: none !important;
    }

    .btn-login-movil {
        display: block !important;
    }

    .aviso-margin {
        margin-top: 15px;
        margin-bottom: 133px;
    }

    .acordion-mobil {
        display: block;
        text-align: center;
    }

}
  
@media only screen and (min-width: 600px) {
   .grid-paper {
        display: flex !important;
   }

   .grid-paper-mobil {
        display: none !important;
    }

    .btn-login {
        display: block !important;
    }

    .btn-login-movil {
        display: none !important;
    }
    
    .acordion-mobil {
        display: none;
    }
}

/* Nuevos estilos */
/* Nueva linea de tiempo */
.linea-tiempo {
    width: 100%;
    height: 175px;
    background-color: #f3f3f3;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1px;
    overflow-x: auto;
    overflow-y: hidden;
}

.card-linea-tiempo {
    display: flex;
    align-items: center;
    width: 260px;
    min-width: 200px;
    height: 175px;
    background-color: #f3f3f3;
    color: #fff;
}

.card-linea-tiempo_contenedor {
    position: relative;
    top: -40px;
    padding: 17px;
    height: 110px;
}

.card-linea-tiempo_contenedor_aux {
    display: flex;
    justify-content: center;
    width: 100%;
}

.card-linea-tiempo_text_aux {
    font-size: 45px;
    font-weight: 400;
    margin: 0;
}

.card-linea-tiempo_text_aux > svg {
    font-size: 45px;
}

.card-linea-tiempo_num {
    font-size: 30px;
    font-weight: 900;
    margin: 0;
}

.card-linea-tiempo_text {
    font-size: 19px;
    font-weight: 400;
    margin: 0;
}

.line-arrow-next {
    margin-left: 10px;
    cursor: pointer;
}

.line-arrow-back {
    margin-right: 10px;
    cursor: pointer;
}

.card-linea-tiempo_check {
    position: relative;
}

.card-linea-tiempo_check img{
    width: 40px;
    position: absolute;
    left: -52px;
    bottom: 27px;
}


@media only screen and (max-width: 860px) {
    .linea-tiempo {
        justify-content: start;
        height: 200px;
    }

    .card-linea-tiempo {
        height: 200px;
    }
}

/* Seccion de introduccion */
.container-intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 80px 450px;
}

.sub-container-intro {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
}

.continair-intro_exp {
    width: 50%;
}

.container-intro_titulo {
    color: #03a9f4;
    font-size: 24px;
    font-weight: 600;
}

/* .container-intro_text {

} */

.container-intro_video {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 400px;
    margin-bottom: 80px;
    background-color: #f3f3f3;
}

.container-intro_video > iframe {
    width: 100%;
    max-width: 600px;
    height: 320px;
} 

.container-video-beca-aceptada {
    font-size: 24px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    width: 100%;
    height: 450px;
    margin-bottom: 80px;
    padding-top: 30px;
    padding-bottom: 20px;
    background-color: #f3f3f3;
    flex-direction: column;
    align-content: center;
}

.container-video-beca-aceptada > iframe {
    width: 100%;
    max-width: 600px;
    height: 320px;
} 

.container-intro_select_otra_beca {
    text-align: center;
    margin-bottom: 88px;
}

.container-intro_select_otra_beca_nota {
    font-style: italic;
    font-size: 14px;
    font-weight: 400;
}

@media only screen and (max-width: 1920px) {
    .container-intro {
        margin: 80px 350px;
    }
}

@media only screen and (max-width: 1700px) {
    .container-intro {
        margin: 80px 250px;
    }
}

@media only screen and (max-width: 1356px) {
    .container-intro {
        margin: 80px 150px;
    }
}

@media only screen and (max-width: 860px) {
    .container-intro {
        margin: 90px 20px;
    }

    .sub-container-intro {
        flex-direction: column;
    }
}

/* Seccion explicacion sobre certificados */

.container-explain {
    background-color: #f3f3f3;
}

.sub-container-explain {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 60px 150px;
    gap: 50px;
}

.container-explain_texto {
    width: 30%;
}

.container-explain_texto_titulo {
    font-weight: 500;
    font-size: 21px;
}

.container-explain_video  {
    max-width: 600px;
    width: 50%;
}

.container-explain_video > iframe {
    width: 100%;
    height: 320px;
} 

@media only screen and (max-width: 860px) {
    .container-explain_texto {
        width:100%;
    }
    
    .sub-container-explain {
        flex-direction: column;
        padding: 60px 20px;
    }

}


/* Seccion de donde se enlistan las cards */
.container-cards {
    display: flex;
    flex-direction: column;
    margin: 80px 150px;
    gap: 30px;
}

.container-cards_intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.container-cards_intro_titulo {
    font-weight: 600;
    font-size: 21px;
}

.container-cards_list {
    display: flex;
    justify-content: center;
}

.container-cards_list_item {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 35px;
}

.card-main-n_movil {
    display: flex;
    flex-direction: column;
    border: 1px solid blue;
}

.card-main-n_movil_head {
    display: flex;
    place-items: center;
    text-align: center;
    justify-content: center;
    margin-top: 15px;
}

.card-main-n_movil_title {
    width: 60%;
    font-size: 20px;
    font-weight: 500;
    display: flex;
    height: 125px;
    color: #888;
    padding: 0px 23px;
    align-items: center;
    margin-left: 38px;
}

.card-main-n_movil_img {
    width: 40%;
}

.card-main-n_movil_text {
    border: solid 1px #d9d9d9;
    border-top: none;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    padding: 23px 38px;
    white-space: pre-line;
}

@media only screen and (max-width: 860px) {
    .container-cards {
        margin: 80px 20px;
    }

    .container-cards_list_item {
        width: 100%;
        max-width: 40rem;
    }

    .boton-cursera {
        justify-content: end !important;
    }
}

/* Para rediseño de las preguntas del home */
.container_caracteristicas-requerisitos {
    display: flex;
    gap: 20px;
}

.container_carac-title {
    font-size: 22px;
    font-weight: 600;
    margin-left: 20px;
}

.container_caracteristicas-requerisitos_req {
    width: 50%;
    background-color: #66badf;
    color: #ffffff;
    padding: 37px 42px;
}

.container_caracteristicas-requerisitos_req-title {
    font-size: 22px;
    text-align: center;
}

.container_caracteristicas-requerisitos_caract {
    width: 50%;
    background-color: #f7f7f7;
}

.acordion-container_home {
    position: initial !important;
    margin-top: 30px;
}

.acordion-item-detail_home {
    width: 100% !important;
}

.acordion-item-detail_home .MuiTypography-root {
    margin: 0px 0px !important;
    padding: 8px 32px 31px !important;
}

@media only screen and (max-width: 860px) {
    .container_caracteristicas-requerisitos {
        flex-direction: column;
        align-items: center;
    }

    .container_caracteristicas-requerisitos_req {
        width: 100%;
    }
    
    .container_caracteristicas-requerisitos_caract {
        width: 100%;
    }
}

/* Seccion de las preguntas frecuentes */
.acordion-root .MuiAccordion-root:before {
    height: 0px;
}

.acordion-root .MuiAccordionSummary-content {
    justify-content: center;
}

.acordion-root .MuiAccordionSummary-content .makeStyles-heading-3 {
    font-size: 22px;
    font-weight: 600;
}

.acordion-root .MuiTreeItem-label {
    color: #000;
}

.acordion-root .css-1g86id8-MuiTreeItem-content .MuiTreeItem-iconContainer svg{
    font-size: 20px;
    color: #000;
}

.acordion-container {
    background-color: #f7f7f7 !important;
    margin-bottom: 5px !important;
    box-shadow:none !important;
}

.sub_acordeon {
    
}

.acordion-item-ask {
    padding: 0px 292px !important;
    min-height: 59px !important;
}

.acordion-item-ask_home {
    padding: 0px 67px !important;
}

.acordion-item-detail {
    width: 100%;
    justify-content: center; 
}

.acordion-item-detail .MuiTypography-root {
    margin: 0px 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.check-acuerdo {
    margin-top: 15px;
}

.check-acuerdo .MuiTypography-root{
    margin: 0px 0px;
}

.alert-acordion {
    width: 100%;
}
.acordion-item-ask .MuiIconButton-root {
    background-color: #50a4e0;
    color: #fff;
    width: 10px;
    height: 10px;
}

.acordion-item-ask .MuiIconButton-root:hover {
    background-color: #167cc5;
}

@media only screen and (max-width: 860px) {
    .acordion-item-ask {
        padding: 0px 50px !important;
    }

    .acordion-item-detail .MuiTypography-root {
        margin: 0px 20px;
    }
}

/* Seccion de inicio de registro */
.container-seccion-reg {
    display: flex;
    flex-direction: column;
    margin: 50px 150px;
}

.container-seccion-reg_titulo {
    display: flex;
    justify-content: center;
    color: #f9a700;
    font-size: 25px;
    font-weight: 600;
}

.container-seccion-reg_boton {
    width: 100% !important;
    justify-content: center !important;
}

.container-seccion-reg_boton button {
    margin: 0px !important;
}

.container-seccion-reg_nota {
    display: flex;
    justify-content: center;
    font-size: 15px;
    font-style: italic;
    margin-top: 15px;
}

@media only screen and (max-width: 860px) {
    .container-seccion-reg {
        margin: 50px 20px;
    }
}

/* Seccion contacto */
.content-contacto {
    height: 100%;
    background-image: url("../img/CCG_contacto2.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    padding: 100px 0px;
    margin-bottom: 25px;
}

.content-contacto_content {
    display: flex;
    justify-content: end;
    gap: 50px;
}

.content-contacto_content > div {
    width: 50%;
}

/* .content-contacto_content_text {

} */

.content-contacto_content_text_titulo {
    font-size: 25px;
    font-weight: 900;
}

.content-contacto_content_text_datos_contacto {
    display: flex;
    font-size: 22px;
}
.content-contacto_content_text_soporte_ayuda{
    font-size: 22px;
}

.content-contacto_content_text_datos_contacto > a {
    text-decoration: none;
    color: #fff;
}

.content-contacto_content_text_datos_contacto > a:hover {
    text-decoration: none;
    color: #fff;
}

.logo_email {
    color: blue;
    width: 1.5em !important;
    height: 1.5em !important;
}

.logo_whatsapp {
    color: green;
    width: 1.5em !important;
    height: 1.5em !important;
}

@media only screen and (max-width: 860px) {
    .content-contacto_content {
        flex-direction: column;
        align-items: center;
    }

    .content-contacto_content > div {
        width:100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

/* Seccion para footer */
.container-footer {
    display: flex;
    -webkit-clip-path: polygon(0 0, 100% 25%, 100% 100%, 0% 100%);
    clip-path: polygon(0 0, 100% 25%, 100% 100%, 0% 100%);
    flex-direction: column;
    align-items: center;
    color: #fff;
    background-color: #2E3B55;
    font-weight: 300;
    padding: 100px 0px;
    margin-top: 30px;
}

.container-footer_data {
    display: flex;
    align-items: center;
    padding: 50px 150px;
}

.container-footer_data a {
    text-decoration: none;
}

.container-footer_data > div {
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-right: solid 1px;
}

.container-footer_data > div:last-child {
    border-right: none;
}

.container-footer_data > div > p, .container-footer_data > div > span {
    margin: 0px 50px;
}

.container-footer_redes_sociales {
    display: flex;
    margin-bottom: 30px;
    gap: 30px;
}

.container-footer_redes_sociales img {
    width: 50px;
}

@media only screen and (max-width: 1080px) {
    .container-footer_data {
        padding: 50px 0px;
    }
}


@media only screen and (max-width: 912px) {
    .container-footer_data {
        flex-direction: column;
        justify-items: center;
        padding: 0px 0px;
    }

    .container-footer_data > div { 
        border-right: none;
        border-bottom: solid 1px;
        width: 340px;
        text-align: center;
    }

    .container-footer_derechos {
        text-align: center;
    }
}

/* Seccion de los estatus de la beca */
.container-seccion-estatus{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 90px 150px;
}

.container-seccion-estatus_titulo{
    color: #03a9f4;
    font-size: 23px;
    font-weight: 600;
}

/* .container-seccion-estatus_texto{

}
 */

@media only screen and (max-width: 860px) {
.container-seccion-estatus {
    margin: 90px 20px;
}
}


/* Seccion de experiencias */
.contiainer-seccion-exp {
    margin: 50px 150px;
}

.contiainer-seccion-exp_titulo {
    font-weight: 600;
    text-align: center;
    font-size: 25px;
}

.contiainer-seccion-exp_texto {
    text-align: center;
 }

.contiainer-seccion-exp_cards {
    margin-top: 60px;
}

.contiainer-seccion-exp_cards .slick-slide {
    display: flex !important;
    justify-content: center;
}

.contiainer-seccion-exp_cards .slick-track {
    left: 10px;
}


.contiainer-seccion-exp_cards .slick-prev:before, 
.contiainer-seccion-exp_cards .slick-next:before {
    font-size: 60px;
    color: #000;
    opacity: 1 !important;
}

.contiainer-seccion-exp_cards .slick-prev:before {
    content: '\2329';
}

.contiainer-seccion-exp_cards .slick-next:before {
    content: '\232A';
}

@media only screen and (max-width: 860px) {
    .contiainer-seccion-exp{
        margin: 50px 20px;
    }

    .contiainer-seccion-exp_cards .slick-prev {
        left: -2px;
        z-index: 2;
    }

    .contiainer-seccion-exp_cards .slick-next {
        right: 4px;
    }
}

.conatiner-beca-otorgada {
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-bottom: 50px;
}


/* Seccion para el mensaje de beca no otorgada */
.container-dismiss {
    margin: 120px 150px;
    text-align: center;
}

.container-dismiss-text_black {
    font-weight: 900;
    font-size: 18px;
}

@media only screen and (max-width: 860px) {
    .container-dismiss {
        margin: 120px 20px;
    }
}

.container-join {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f7f7f7;
    gap: 35px;
    height: 400px;
    padding: 150px;
}

.container-join_img > img{
    height: 350px;
}

.container-join_text {
    width: 30%;
}

.container-join_text_p1 {
    font-size: 24px;
    font-weight: 200;
}

/* .container-join_text_p2 {
    
} */

.container-join_text_p3 {
    font-weight: 600;
}

.container-join_text_p3 a {
    text-decoration: none;
    color: #000;
}

@media only screen and (max-width: 860px) {
    .container-join {
        flex-flow: column;
        padding: 0px;
        height: 100%;
    }
    .container-join_img > img{
        margin-top: 20px;
    }
    .container-join_text {
        width: 50%;
    }
}
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;800&display=swap');

.soporte-ti{
    background: #4285F4;
    color: #fff;
}

.analisis{
    background: #E84335;
    color: #fff;
}

.gestion{
    background: #E6ac27;
    color: #fff;
}

.ux{
    background: #1F8839;
    color: #fff;
}

.MuiCardHeader-content  > span{
    font-weight: lighter;
    font-size: 1.2vw;
    width: 10vw;
    height: 14vh;
}

.card-cert {
    border:1px #aaaaaa;
    margin-bottom: 25px;
}

.card-cert > .MuiPaper-root{
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
}

.MuiCardContent-root > p{
    font-size: 16px;
    margin-top: 2%;
}

.boton-cursera {
    margin-top: 20px;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.boton-cursera-text {
    color: #4285f4;
    font-weight: bold;
}

.boton-cursera-boton {
    width: 9%;
    margin-left: 10px;
    text-align: left;
}

.boton-cursera-boton > a {
    cursor: pointer;
}

.card_text_finalizado {
    font-weight: 600;
    color: #666666;
}

.container-redes {
    display: flex;
    margin: 50px 150px;
}

.container-redes_img {
    display: flex;
    width: 100%;
}

.container-redes_mensaje {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    font-size: 20px;
}

.cert-finish {
    padding: 2px 26px;
    color: #fff;
}

@media only screen and (max-width: 600px) {
    .cards-movil {
        display: flex;
    }

    .cards-no-movil {
        display: none !important;
    }

    .display-card-mode {
        display: block;
    }

    .container-redes {
        display: flex;
        margin: 50px 20px;
    }
}
  
@media only screen and (min-width: 600px) {
    .cards-movil {
       display: none; 
    }

    .cards-no-movil {
        display: flex !important;
    }

    .display-card-mode {
        display: flex;
    }
}

/* NUEVOS ESTILOS PARA CARDS NUEVOS */
.card_main_n {
    display: flex;
    gap: 15px;
    border: 1px solid blue;
    padding: 35px;
    width: 580px;
    height: auto;
}

.card_box_title-img {
    width: 35%;
}

.card-box_text {
    width: 65%;
    margin-top: 10px;
}

.card_title {
    font-size: 23px;
    font-weight: 600;
    color: #888888;
}

.card_img {
   text-align: center;
}

.card_ver-mas {
    display: flex;
    gap: 20px;
    margin-top: 50px;
}

.card_ver-mas_text {
    width: 70%;
}

.card_ver-mas_button {
    display: flex;
    align-items: center;
    width: 30%;
}

.card_ver-mas_button > a {
    background-color: blue;
    color: white !important;
    text-decoration: none;
    padding: 14px 17px;
    width: 107px;
    text-align: center;
}

.card_check {
    position: relative;
    bottom: 5px;
    color: #0e8f00;
}
/* .card_main_n,.card_title,.card_img, .card_text  {
    display: flex;
    min-height: 250px;
    height: auto;
    align-items: stretch;
}

.card_title>div {
    width: 100%;
    padding: 0px 10px;
    color: #fff;
    font-weight: bolder;
}

.card_img>div {
    width: 100%;
    padding: 0px 10px;
}

.card_text>div {
    width: 100%;
    padding: 15px 15px;
}

.card_title {
    display: flex;
    align-items: center;
    min-width: 150px;
    width: 30%;
    text-align: center;
}



.card_img {
    display: flex;
    align-items: center;
    min-width: 130px;
    width: 20%;
    text-align: center;
    border-top: solid #c6baba 1px;
    border-bottom: solid #c6baba 1px;
}

.card_text {
    display: flex;
    align-items: center;
    min-width: 150px;
    width: 50%;
    border-top: solid #c6baba 1px;
    border-bottom: solid #c6baba 1px;
    border-right: solid #c6baba 1px;
    border-radius: 0px 15px 15px 0px;
    white-space: pre-line;
} */


/* Seccion cards de experiencia */
.container-card-person-exp {
    width: 320px;
    height: 680px;
    min-width: 320px;
    background-color: #f3f3f3;
    border-radius: 20px;
}

.container-card-person-exp_img {
    width: 200px;
    height: 200px;
    background-color: #fff;
    position: relative;
    top: 55px;
    border-radius: 100px;
    margin: 0 auto;
    background-size: cover;
    background-position: 50% 10%;
}

.container-card-person-exp_nombre {
    position: relative;
    text-align: center;
    top: 70px;
    margin: 0px 20px;
}

.container-card-person-exp_text {
    position: relative;
    top: 70px;
    margin: 0px 40px;
    text-align: center;
}
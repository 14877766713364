.content-redes {
    display: flex;

}

.content-redes button {
    width: 60px;
}

.content-redes img {
    width: 60px;
}
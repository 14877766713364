.menu-item-form {
    display: flex !important;
    justify-content: left !important;
    padding: 10px 30px !important;
}

.form-cheks {
  margin-top: 10px;
}

.grid-boton {
  display: flex;
}

.boton-form {
  padding: 10px 10px !important;
  width: 267px !important;
}
  

.form-modal {
  width: 600px;
}

.form-modal_img {
  margin: 0px;
  margin-left: 97px;
}

.form-modal_titulo {
  margin: 10px 50px;
  text-align: center;
  font-weight: 700;
  font-size: 18px;
}

.form-modal_texto {
  margin: 0px 50px;
  margin-bottom: 75px;
  text-align: center;
}



@media only screen and (max-width: 600px) {
  .form-modal {
    width: 300px;
  }
}
body {
  background-color: #FFFFFF;
  padding: 0px;
  margin: 0;
}
h1, h2, p, ul, li {
  font-family: sans-serif;
}
ul.header li {
  display: inline;
  list-style-type: none;
  margin: 0;
}
ul.header {
  background-color: #2E3B55;
  padding: 0;
}
ul.header li a {
  color: #FFF;
  font-weight: bold;
  text-decoration: none;
  padding: 20px;
  display: inline-block;
}
.content {
  background-color: #FFF;
  padding: 20px;
}
.content h2 {
  padding: 0;
  margin: 0;
}
.content li {
  margin-bottom: 10px;
}

.content-formulario {
  padding: 20px 130px;
}

.active {
  background-color: #0099FF;
}

.footer{
  padding: 1rem;
  background-color: #2E3B55;
  color: 'white';
  bottom: 0;
  left: 0;
  /*mwidth: 100%;*/
  max-width: 100%;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  height: 150px;
  justify-content: center;
}

.App {
  font-family: sans-serif;
  text-align: center;
  display: flex;
}

.div_test{
  border: 1px solid red;
}

.iconDetails {
  margin-left:2%;
 float:left; 
 height:40px;
 width:40px; 
 } 
 
 .container2 {
     width:100%;
     height:auto;
     padding:1%;
 }

 test {
  margin:0px;
}

.column1 {
  text-align: left;
  float: left;
  width: 10%;
  max-width: 75px;
  max-height: 75px;
  
}

.column2 {
  float: left;
  width: 80%;
  text-align: left;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 0;
  margin-left: 0;
  border-top: 0;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
}
/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
  text-align: left;
}

.logo {
  max-width: 150px;
  max-height: 150px;
  margin-right: 10px;
}

.logoL {
  max-width: 150px;
  max-height: 150px;
  margin-right: 10px;
}

.row10:after {
  content: "";
  display: table;
  clear: both;
  text-align: left;
}

.column10 {
  color: 'white';
  text-align: left;
  float: left;
  width: 50%;
  max-width: 75px;
  max-height: 75px;
}

.loginButton {
  border-color: '#2196F3';
  color: '#2196F3';
}

.button-link {
  background: none!important;
  border: none;
  padding: 0!important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: black;
  text-decoration: underline;
  cursor: pointer;
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input::before {
  color: black;
  content: 'Select some files';
  display: inline-block;
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
}
.custom-file-input:hover::before {
  border-color: black;
}
.custom-file-input:active::before {
  background: -webkit-linear-gradient(to bottom, #e3e3e3, #f9f9f9);
}

.form-cheks .css-j204z7-MuiFormControlLabel-root {
  align-items: flex-start;
}

.form-cheks .css-ahj2mt-MuiTypography-root {
  margin-top: 8px;
}

.head-formulario {
  margin: 118px 150px;
  text-align: center;
  font-size: 30px;
}

@media only screen and (max-width: 860px) {
  .head-formulario {
    margin: 118px 20px;
  }
}

@media only screen and (max-width: 600px) {
  .footer-no-mobil{
    display: none;
  }

  .footer-mobil{
    display: block;
    -webkit-clip-path: polygon(0 2%, 100% 7%, 100% 100%, 0% 100%);
    clip-path: polygon(0 2%, 100% 7%, 100% 100%, 0% 100%);
  }

  .content-formulario {
    padding: 20px 20px;
  }

  .content-no-movil {
    padding: 20px;
  }
}

@media only screen and (min-width: 600px) {
  .footer-no-mobil{
    display: flex;
  }

  .footer-mobil{
    display: none;
  }

  .content-no-movil {
    padding: 0px;
  }
}